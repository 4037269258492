@import "./styles/variables";

// Your variable overrides can go here, e.g.:
// $h1-font-size: 3rem;

@import "~bootswatch/dist/darkly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";
@import "~react-toastify/scss/main";

footer {
  background-color: #1d1d1d;
  padding: 1em;

  .fixed-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.btn-twitch {
  color: #fff;
  border-color: #000;
  background-color: $twitchPurple;
  &:hover {
    background-color: darken($twitchPurple, 10%);
  }
}

.bg-twitch-purple {
  background-color: $twitchPurple;
}

.user-avatar:hover {
  background-color: $twitchPurple;
}

.approved-user-list {
  li {
    list-style-type: none;
  }
}

.drop-shadow-sm {
  box-shadow: 0.3em 0.3em 0.5em rgba(0, 0, 0, 0.5);
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.calendar-card {
  min-width: 200px;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem !important;
  text-align: center;
  &:hover {
    cursor: pointer;
    background-color: $twitchPurple;
  }
}

.accordion > .card > .card-header {
  &:hover {
    background-color: $primary;
  }
}
