@import "../variables";
@import "~bootswatch/dist/darkly/variables";

.rbc-off-range-bg {
  background-color: #333;
}

.rbc-today {
  background-color: $success;
}

.rbc-toolbar button {
  color: #fff;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #222;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 2.5vmax;
}
